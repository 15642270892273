import * as React from "react"
import * as styles from "./Home.module.css"
import { Helmet } from 'react-helmet'

export default function Home(){
  const audio = React.useRef<HTMLAudioElement | null>(null)

  const onClick = () => {
    audio.current && audio.current.pause()
    audio.current = new Audio(Math.floor(Math.random() * 5) === 0 ? '/squish-long.mp3' : '/squish-short.mp3')
    audio.current.play();
  }

  return (
    <div className={styles.home}>
      <Helmet title="Hydroto!" />
      <div className={styles.avatar} onClick={onClick} />
    </div>
  )
}