import * as React from "react"
import Home from "../components/pages/home"
import "./global.css"

// markup
const IndexPage = () => {
  return (
    <main>
      <Home />
    </main>
  )
}

export default IndexPage
